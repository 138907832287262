<template>
    <div class="d-flex flex-column">
      <span v-if="!hideTitle" class="font-weight-bold">{{ title }}</span>
      <a-select
        :allow-clear="allowClear"
        :value="value"
        size="large"
        @change="handleChange"
        :disabled="disabled"
      >
      <template #placeholder><span style="color: #a1a1c2;">Select product</span></template>
        <a-select-option
          v-for="productType in products"
          :key="productType.value"
        >
          {{ productType.label }}
        </a-select-option>
      </a-select>
    </div>
</template>

<script>
export default {
  name: 'productSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: 'Type',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      products: [
        {
          label: 'Banner',
          value: 'banner',
        },
        {
          label: 'Story',
          value: 'story',
        },
      ],
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
