<template>
  <div class="d-flex flex-column">
    <span v-if="!hideTitle" class="font-weight-bold">Active status</span>
    <div class="d-flex align-items-center switch__container"
         :class="{'flex-row': !reverse, 'flex-row-reverse': reverse}">
      <span class="font-size-16 font-weight-light">{{ switchTitle }}</span>
      <a-switch @change="handleChange" :checked="value" :disabled="disabled"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activeStatus',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    switchTitle: {
      type: String,
      default: 'Active only',
    },
    value: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.switch__container {
  height: 40px;
  gap: 1rem;
}
</style>
